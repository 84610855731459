<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-actions"></div>
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="项目">
              <a-select
                class="form-control lg"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :filterOption="filterByPY"
              ></a-select>
            </a-form-item>
            <a-form-item label="异常状态">
              <a-select
                class="form-control"
                v-decorator="['exceptionType']"
                placeholder="请选择异常状态"
                allowClear
                :options="typesMap.exceptionType"
              ></a-select>
            </a-form-item>
            <a-form-item label="异常日期">
              <a-range-picker v-decorator="['date']" />
            </a-form-item>
            <a-form-item label="处理状态">
              <a-select
                class="form-control"
                v-decorator="['status']"
                placeholder="请选择处理状态"
                allowClear
                :options="typesMap.status"
              ></a-select>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <a-form-item label="异常项目">{{ modal.data.projectName }}</a-form-item>
        <a-form-item label="异常日期">{{ modal.data.createTime }}</a-form-item>
        <a-form-item label="异常类型">{{ modal.data.exceptionTypeName }}</a-form-item>
        <a-form-item label="异常详情">
          <p v-if="modal.data.exceptionType === 'RS'">
            前一日：{{ modal.data.attendanceClockPeopleExceptionVO.beforeYesterdayCount }} ， 统计日：{{
              modal.data.attendanceClockPeopleExceptionVO.yesterdayCount
            }}
          </p>
          <p v-if="modal.data.exceptionType === 'JC'">
            进场：{{ modal.data.attendanceInOutExceptionVO.inCount }}，出场：{{
              modal.data.attendanceInOutExceptionVO.outCount
            }}
          </p>
        </a-form-item>
        <a-form-item label="处理方案">
          <a-textarea
            :disabled="modal.type === 'DETAIL'"
            placeholder="联系谁处理/问题排查结论/处理方法/是否已恢复正常"
            v-decorator="[
              'dealResult',
              {
                rules: [{ required: true, message: '请输入处理方案' }],
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getProjectList } from '@/service/getData'
import PictureCardListUpload from '@/components/PictureCardListUpload'
export default {
  components: { PictureCardListUpload },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '异常项目',
        width: '12%',
        align: 'center',
        dataIndex: 'projectName',
      },
      {
        title: '异常类型',
        width: '8%',
        align: 'center',
        dataIndex: 'exceptionTypeName',
      },
      {
        title: '异常详情',
        width: '12%',
        align: 'center',
        customRender: (text, row, index) => {
          return (
            <div>
              {row.exceptionType === 'RS' && (
                <div class="whitespace-nowrap">
                  <p>
                    考勤({row.attendanceClockPeopleExceptionVO.yesterdayDate}) :{' '}
                    {row.attendanceClockPeopleExceptionVO.yesterdayCount}
                  </p>
                  <p>
                    考勤({row.attendanceClockPeopleExceptionVO.beforeYesterdayDate}) :{' '}
                    {row.attendanceClockPeopleExceptionVO.beforeYesterdayCount}
                  </p>
                </div>
              )}
              {row.exceptionType === 'JC' && (
                <div class="whitespace-nowrap">
                  <p>
                    进场({row.attendanceInOutExceptionVO.date}) : {row.attendanceInOutExceptionVO.inCount}
                  </p>
                  <p>
                    出场({row.attendanceInOutExceptionVO.date}) : {row.attendanceInOutExceptionVO.outCount}
                  </p>
                </div>
              )}
            </div>
          )
        },
      },
      {
        title: '偏离值',
        width: '6%',
        align: 'center',
        dataIndex: 'deviation',
      },
      {
        title: '告警时间',
        width: '12%',
        align: 'center',
        dataIndex: 'createTime',
      },
      {
        title: '告警人员',
        width: '12%',
        align: 'center',
        dataIndex: 'alarmUser',
      },
      {
        title: '状态',
        width: '6%',
        align: 'center',
        dataIndex: 'status',
        customRender: (text, row, index) => {
          return <span>{this.translateType(text, 'status')}</span>
        },
      },
      {
        title: '处理时间',
        width: '12%',
        align: 'center',
        dataIndex: 'dealTime',
      },
      {
        title: '处理人',
        width: '6%',
        align: 'center',
        dataIndex: 'dealUser',
      },
      {
        title: '操作',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <span>
                {row.status === 0 && (
                  <a
                    class="row-action-btn"
                    onClick={() => {
                      this.audit(row)
                    }}
                  >
                    处理
                  </a>
                )}
                {row.status === 1 && (
                  <a
                    class="row-action-btn"
                    onClick={() => {
                      this.showDetail(row)
                    }}
                  >
                    查看
                  </a>
                )}
              </span>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        type: 'AUDIT', // AUDIT || DETAIL
        data: {},
      },

      projectList: [],
    }
  },
  computed: {
    typesMap() {
      return {
        status: [
          { value: 0, label: '未处理' },
          { value: 1, label: '已处理' },
        ],
        exceptionType: [
          { value: 'JC', label: '进出比例异常' },
          { value: 'RS', label: '考勤人数异常' },
        ],
      }
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/attendance/exception/monitor/list', {
          ...this.formatTimeRangeParams(this.getPageParams(_pageNo, _pageSize), 'date'),
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async showDetail(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.type = 'DETAIL'
      this.modal.title = '处理记录'
      this.modal.data = { ...row }
      this.safeSetFieldsValue(this.modal.form, { ...row })
    },
    async audit(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.type = 'AUDIT'
      this.modal.title = '异常处理'
      this.modal.data = { ...row }
      this.safeSetFieldsValue(this.modal.form, { ...row })
    },

    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const actionUrl = '/attendance/exception/monitor/deal'
            const { id } = this.modal.data
            await this.$axios.post(actionUrl, {
              id,
              ...params,
            })
            this.$message.success('操作成功!')
            this.resetModal()
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
  },
  async mounted() {
    this.projectList = await getProjectList()
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
